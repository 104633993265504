import { useEffect, useRef } from 'react'
import useTheme from '../../hooks/useTheme'

import Nav from '../nav'

import { TbMoonStars } from 'react-icons/tb'
import { HiLightBulb } from 'react-icons/hi'

import './header.css'

function Header({ shouldShowNav = true }) {
  const headerRef = useRef(null)

  const {isDarkTheme, toggleTheme} = useTheme()

  useEffect(() => {
    if(headerRef.current == null) return
    const header = headerRef.current
    const resizeObserver = new ResizeObserver(() => {
      const headerHeight = header.getBoundingClientRect().height
      const scrollPadding = headerHeight
      document.documentElement.style.setProperty("--page-scroll-padding", `${scrollPadding}px`)
    })

    resizeObserver.observe(header)

    return () => {
      resizeObserver.unobserve(header)
    }
  }, [headerRef])

  return (
    <header className="main-header" ref={headerRef}>
      <div className="main-header-content">
        <a className="company-info" href='/'>
          <img className="company-info__logo" src="/images/logos/minohealth-logo.png" alt="minohealth" />
          <h1 className="company-info__name">minoHealth AI Labs</h1>
        </a>

        <div className="main-header__action-btns">
          <button
            type="button"
            className="toggle-theme-btn toggle-theme-btn--mobile icon-btn"
            aria-pressed={isDarkTheme}
            onClick={toggleTheme}
          >
            {
              isDarkTheme
                ? <HiLightBulb className="icon-btn__icon" />
                : <TbMoonStars className="icon-btn__icon" />
            }
            <span className="sr-only">Dark theme</span>
          </button>

          {shouldShowNav? <Nav /> : null}

          <button
            type="button"
            className="toggle-theme-btn toggle-theme-btn--desktop icon-btn"
            aria-pressed={isDarkTheme}
            onClick={toggleTheme}
          >
            {
              isDarkTheme
                ? <HiLightBulb className="icon-btn__icon" />
                : <TbMoonStars className="icon-btn__icon" />
            }
            <span className="sr-only">Dark theme</span>
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header
