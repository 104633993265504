import { useEffect, useLayoutEffect } from "react";
import { useLoaderData, useOutletContext } from "react-router-dom";
import ServerRedirect from "../../components/routes/ServerRedirect";
import HtmlRenderer from "../../components/html-renderer";
import { getInteractiveComponent } from "./interactive-components";
import useEventListener from "../../hooks/useEventListener";
import { throttle } from "../../utils/rate-limiters";

import { BsTwitter, BsFacebook, BsLinkedin } from 'react-icons/bs'
import { RiInstagramFill } from 'react-icons/ri'

import "./blog-post.css";

export default function BlogPost() {
  /** @type {import("./api").PostWithContent} */
  const post = useLoaderData()
  const backgroundHsl = post?.accent?.background ?? "350 50% 60%"
  const textHsl = post?.accent?.text ?? "0 0% 0%"
  const { setBlogAccentHsl } = useOutletContext()

  useLayoutEffect(() => {
    setBlogAccentHsl(backgroundHsl, textHsl)
    return () => setBlogAccentHsl(undefined, undefined)
  }, [])

  // function updatePageScrollProgress() {
  //   document.body.style.setProperty(
  //     "--page-scroll-progress", 
  //     window.scrollY / (document.body.scrollHeight - window.innerHeight)
  //   )
  // }

  // useEffect(updatePageScrollProgress, [])
  // useEventListener(window, "scroll", throttle(updatePageScrollProgress, 10))

  if(post === null) {
    return <ServerRedirect to="/404" />
  }

  return (
    <div className="blog-post">
      <article className="blog-post__content">
        <div>
          <BlogPostRenderer post={post} />
        </div>

        <div className="blog-post__content-footer">
          <div style={{display: "flex", alignItems: "center", gap: "0.75rem"}}>
            <p>Published {post.date}</p>
            <nav aria-label="social media" className="blog-post__social-media-nav">
              <ul>
                <li>
                  <a href="https://www.instagram.com/minohealth/" target="_blank" rel="noreferrer"><RiInstagramFill /></a>
                </li>
                <li>
                  <a href="https://www.facebook.com/minoHealth/" target="_blank" rel="noreferrer"><BsFacebook /></a>
                </li>
                <li>
                  <a href="https://twitter.com/minohealth" target="_blank" rel="noreferrer"><BsTwitter /></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/minohealth/" target="_blank" rel="noreferrer"><BsLinkedin /></a>
                </li>
              </ul>
            </nav>
          </div>

          <p>Have something to say? Email us at <a href="mailto:support@minohealth.org">support@minohealth.org</a></p>
        </div>
      </article>
      {/* <div className="blog-post__progress-indicator"></div> */}
    </div>
  )
}

/**
  * @param {Object} props
  * @param {import("./api").PostWithContent} props.post
  */
function BlogPostRenderer({ post }) {
  const components = (() => {
    if(post.components == null) return {}

    return Object.fromEntries(
      post.components.map(name => {
        let interactiveComponent
        try {
          if(!name.includes("-")) throw new Error(`Invalid component name: ${name}`)

          interactiveComponent = getInteractiveComponent(name)
        } catch(error) {
          /** @type {import("../../components/html-renderer").HtmlRendererComponent<string>} */
          interactiveComponent = (node, key) => (
             <BlogPostRendererFallback error={error} key={key} /> 
          )
        }
        return [name, interactiveComponent]
      })
    )
  })();
  Object.assign(components, {
    table(node, key, { props }) {
      return (
        <div className="table-wrapper" key={key}>
          <table {...props}></table>
        </div>
      )
    }
  })

  return (
    <HtmlRenderer components={components}>{post.content}</HtmlRenderer>
  )
}

/** @param {import("react-error-boundary").FallbackProps} props */
function BlogPostRendererFallback({ error }) {
  return (
    <div className="blog-post__error-fallback">
      <strong>Failed to display interactive component</strong>
      <p>{error?.message}</p>
    </div>
  )
}
