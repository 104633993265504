import { useState, useRef, useEffect } from "react";
import useEventListener from "../../hooks/useEventListener";
import { throttle } from "../../utils/rate-limiters";

/**
 * Get the most recent scroll direction on the window
 * 
 * @returns {("up" | "down")?} Direction of most recent window scroll
 */
export default function useWindowScrollDirection() {
  const [direction, setDirection] = useState(null)
  const prevScrollY = useRef(window.scrollY)

  const throttledScrollHandler = useThrottle((scrollY) => {
    setDirection(scrollY > prevScrollY.current ? "down": "up")
    prevScrollY.current = scrollY
  }, 250)
  
  useEventListener(window, "scroll", () => {
    if(window.scrollY < 0) return // TODO: Fix the opposite direction too
    throttledScrollHandler(window.scrollY)
  })

  return direction
}

/**
 * @template T
 * @param {(...args: T) => void} callback
 * @param {number} [delay]
 */
function useThrottle(callback, delay = 250) {
  const timeoutRef = useRef(null)
  const latestArgsRef = useRef(null)
  const canInvokeRef = useRef(true)

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current)
  }, [])

  /** @type {(...args: T) => void} */
  return (...args) => {
    latestArgsRef.current = args

    if(!canInvokeRef.current) return

    callback(...latestArgsRef.current)
    latestArgsRef.current = null
    canInvokeRef.current = false
    
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      canInvokeRef.current = true

      if(latestArgsRef.current != null) {
        callback(...latestArgsRef.current)
        latestArgsRef.current = null
      }
    }, delay)
  }
}
