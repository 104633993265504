import { useRef, forwardRef, useImperativeHandle } from 'react'
import { default as CxInfoSection } from './ComposableInfoSection'

import './info-section.css'

const InfoSection = forwardRef(({ id, heading, subHeading, content, ExtraSubContent, imgWidth, imgHeight }, ref) => {
  const sectionRef = useRef(null)
  useImperativeHandle(ref, () => sectionRef.current)
  
  return (
    <CxInfoSection id={id} ref={sectionRef}>
      <CxInfoSection.Heading>{heading.toUpperCase()}</CxInfoSection.Heading>
      {
        subHeading !== undefined
          ? <CxInfoSection.SubHeading>{subHeading.toUpperCase()}</CxInfoSection.SubHeading>
          : null
      }

      <CxInfoSection.Content>
        {content.map((subSection, index) => (
          <CxInfoSection.SubSection key={index} >
            <CxInfoSection.SubSection.ImageContainer>
              <CxInfoSection.SubSection.Image 
                src={`/images/${subSection.img}`} 
                alt={subSection.img.replace(/\..+/, "").replace(/-/g, " ")} 
                width={imgWidth} 
                height={imgHeight}
                />
            </CxInfoSection.SubSection.ImageContainer>

            <CxInfoSection.SubSection.Content>
              <CxInfoSection.SubSection.Heading>{subSection.heading.toUpperCase()}</CxInfoSection.SubSection.Heading>
              <CxInfoSection.SubSection.Text>{subSection.text}</CxInfoSection.SubSection.Text>

              {ExtraSubContent != null ? <ExtraSubContent data={subSection} index={index} /> : null}
            </CxInfoSection.SubSection.Content>
          </CxInfoSection.SubSection>
        ))}
      </CxInfoSection.Content>
    </CxInfoSection>
  )
})

export default InfoSection
