// @ts-check
"use strict"

import React from "react"
import { getAttribute } from "../../../components/html-renderer"
import MoremiUseCases from "./moremi-use-cases"
import MoremiQABlock from "./moremi-qa-block"
import { CustomBarChart as MoremiBarChart, getDataFromTable } from "./moremi-charts"
import { SyntaxKind } from "html5parser"

/** @type {Record<HyphenatedString, import("../../../components/html-renderer").HtmlRendererComponent<HyphenatedString>>} */
const InteractiveComponents = {
  "use-cases-provider"(node, key, { children }) {
    return (
      <MoremiUseCases key={key}>{children}</MoremiUseCases>
    )
  },
  "use-case-select"(node, key ) {
    const useCases = getAttribute(node, "data-use-cases", value => value.split(", ")) ?? []
    return (
      <MoremiUseCases.Select useCases={useCases} key={key} />
    )
  },
  "use-case-panel"(node, key, { children }) {
    const useCase = getAttribute(node, "data-use-case")
    if(useCase == null) throw new Error("Missing attribute \"data-use-case\" on <use-case-panel> element")

    return (
      <MoremiUseCases.Panel useCase={useCase} key={key}>{children}</MoremiUseCases.Panel>
    )
  },
  "qa-block"(node, key, { children }) {
    return (
      <MoremiQABlock key={key}>{children}</MoremiQABlock>
    )
  },
  "qa-question"(node, key, { children }) {
    return (
      <MoremiQABlock.Question key={key}>{children}</MoremiQABlock.Question>
    )
  },
  "qa-answer"(node, key, { children }) {
    return (
      <MoremiQABlock.Answer key={key}>{children}</MoremiQABlock.Answer>
    )
  },
  "bar-chart"(node, key) {
    /** @type {import("html5parser").ITag?} */
    // @ts-ignore
    const table = node.body?.find(node => node.type === SyntaxKind.Tag && node.name === "table")
    if(table == null) throw new Error("Bar chart component must have a table as a child")
    const data = getDataFromTable(table)
    const xAxis = getAttribute(node, "data-x-axis")
    const yAxis = getAttribute(node, "data-y-axis", value => value.split(", "))

    if(xAxis == null || yAxis == null) {
      throw new Error("Both x-axis and y-axis properties are required for the bar chart")
    }

    const domain = getAttribute(node, "data-domain", value => {
      let list = value.split(",").map(item => Number(item.trim()))
      if(list.length != 2) throw new Error("Bar chart domain has to be exactly two numbers")
      return /** @type {[number, number]} */(list)
    })

    return (
      <MoremiBarChart
        data={data}
        xAxis={xAxis}
        yAxis={yAxis}
        domain={domain}
        key={key}
      />
    )
  }
}

/** @param {HyphenatedString} name */
export function getInteractiveComponent(name) {
  if(!(name in InteractiveComponents)) {
    throw new Error(`Unknown interactive component: "${name}"`)
  }

  return InteractiveComponents[name]
}

// TODO: Improve this type
/** @typedef {`${string}-${string}`} HyphenatedString */
