import { useLayoutEffect } from 'react'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'
import useTheme from './hooks/useTheme'
import { THEME } from './utils/theme'

import AppRoutes from './components/routes/AppRoutes'

import './App.css'

gsap.registerPlugin(ScrollTrigger)

function App() {
  const { isDarkTheme } = useTheme()
  useLayoutEffect(() => {
    document.documentElement.dataset.theme = isDarkTheme ? THEME.DARK : THEME.LIGHT
  }, [isDarkTheme])

  return (
    <AppRoutes />
  )
}

export default App;
