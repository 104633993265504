import { useRef, useLayoutEffect } from 'react'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'

import PortfolioCarouselItem from './PortfolioCarouselItem'
import portfolioData from '../../data/portfolio.json'

import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'

import './portfolio-section.css'

gsap.registerPlugin(ScrollTrigger)

function PortfolioSection() {
  const carouselRef = useRef(null)

  function handleCarouselControlClick(e) {
    const carouselWidth = carouselRef.current?.clientWidth
    const direction = e.target.closest('button').dataset.direction === 'left' ? -1 : 1

    carouselRef.current?.scrollBy({
      left: (carouselWidth * 0.75) * direction,
    })
  }

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const portfolioSectionTl = gsap.timeline({
        delay: 0.25,
        scrollTrigger: {
          trigger: ".portfolio-section",
          start: "top 90%",
          toggleActions: "play none none reverse",
        },
        defaults: {
          duration: 0.3,
          ease: "ease-in"
        }
      })

      gsap.set(".portfolio-section__carousel-control", {
        transition: "none"
      })

      portfolioSectionTl
        .from(".portfolio-section__heading", {
          xPercent: -50,
          opacity: 0,
        })
        .from(`.portfolio-section__carousel-control[data-direction="left"]`, {
          opacity: 0,
          xPercent: -75,
          onComplete: function () {
            gsap.set(this.targets(), { clearProps: "all" });
          }
        }, "<")
        .from(`.portfolio-section__carousel-control[data-direction="right"]`, {
          opacity: 0,
          xPercent: 75,
          onComplete: function () {
            gsap.set(this.targets(), { clearProps: "all" });
          }
        }, "<")
        .from(".portfolio-section__carousel-item", {
          opacity: 0,
          yPercent: 50,
          duration: 0.5,
          stagger: 0.15,
          ease: "back.out(1)",
          onComplete: function () {
            gsap.set(this.targets(), { clearProps: "all" });
          }
        })
    })

    return () => ctx.revert()
  }, [])
  
  return (
    <section id="portfolio" className="portfolio-section info-section">
      <div className="portfolio-section__header">
        <h2 className="portfolio-section__heading info-section__heading">{portfolioData.heading.toUpperCase()}</h2>

        <div className="portfolio-section__carousel-controls">
          <button 
            className="portfolio-section__carousel-control"
            onClick={handleCarouselControlClick}
            data-direction="left"
          >
            <BsChevronLeft className="portfolio-section__carousel-control-icon" />
          </button>

          <button 
            className="portfolio-section__carousel-control"
            onClick={handleCarouselControlClick}
            data-direction="right"
          >
            <BsChevronRight className="portfolio-section__carousel-control-icon" />
          </button>
        </div>
      </div>

      <div className="portfolio-section__carousel-wrapper">
        <div className="portfolio-section__carousel" ref={carouselRef}>
          {portfolioData.content.map((item, index) => (
            <PortfolioCarouselItem
              heading={item.heading.toUpperCase()}
              category={item.category.toUpperCase()}
              text={formatMarkdownLinks(item.text)}
              imgSrc={`/images/${item.img}`}
              imgAltText={item.img.replace(/-thumbnail\..+/, "").replace(/-/g, " ")}
              logos={item.logos}
              key={index}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default PortfolioSection

const markdownLinkRegExp = /\[([^\]\n]+)\]\(([^\)\n\s]+)\)/g

export function formatMarkdownLinks(text) {
  return text
    .replace(markdownLinkRegExp, "<a href=\"$2\" target=\"_blank\" rel=\"noopener noreferrer\">$1</a>")
}
