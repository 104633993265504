import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import useOnClickOutside from '../../hooks/useOnClickOutside'

import { FiMenu, FiX } from 'react-icons/fi'

import "./blog-nav.css"

const NavState = {
  OPEN: "open",
  CLOSING: "closing",
  CLOSED: "closed"
}
const NAV_TRANSITION_IN_DURATION = "350ms"
const NAV_TRANSITION_OUT_DURATION = "250ms"

function getMilliseconds(cssDuration) {
  if(cssDuration.endsWith("ms")) return parseFloat(cssDuration.replace("ms", ""))
  if(cssDuration.endsWith("s")) return parseFloat(cssDuration.replace("s", "")) * 1000
  throw new Error("Invalid css duration")
}

export default function BlogNav() {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const [navState, setNavState] = useState()
  const navTransitionDuration = isNavExpanded
    ? NAV_TRANSITION_IN_DURATION
    : NAV_TRANSITION_OUT_DURATION
  const [canMenuAnimate, setCanMenuAnimate] = useState(false)
  const navRef = useRef(null)

  useOnClickOutside(navRef, () => {
    setIsNavExpanded(false)
  })

  useEffect(() => {
    if(isNavExpanded) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isNavExpanded])

  useEffect(() => {
    if(isNavExpanded) {
      setNavState(NavState.OPEN)
    } else {
      setNavState(NavState.CLOSING)
      const timeoutId = setTimeout(() => {
        setNavState(NavState.CLOSED)
      }, getMilliseconds(navTransitionDuration))

      return () => clearTimeout(timeoutId)
    }
  }, [isNavExpanded, navTransitionDuration])
  
  return (
    <nav 
      aria-label="primary"
      className="blog-nav"
      style={{
        "--nav-transition-duration": navTransitionDuration
      }}
      ref={navRef}
    >
      <button 
        type="button"
        title="Menu"
        className="blog-nav__toggle-btn"
        aria-haspopup="true"
        aria-expanded={isNavExpanded}
        aria-controls="blog-nav-body"
        data-can-animate={canMenuAnimate}
        onClick={() => {
          setIsNavExpanded(prev => !prev)
          if(!canMenuAnimate) setCanMenuAnimate(true)
        }}
      >
        {isNavExpanded ? <FiX />: <FiMenu />}
      </button>

      <div 
        id="blog-nav-body" 
        className="blog-nav__body"
        data-state={navState}
      >
        <ul 
          className="blog-nav__list"
          onClick={ev => {
            if(ev.target.tagName.toLowerCase() !== "a") return
            setIsNavExpanded(false)
          }}
        >
          <li><Link to="../blog">Blog home</Link></li>
          <li><Link to="../blog/category/news">Latest News</Link></li>
          <li><Link to="../blog/category/research">Our Research</Link></li>
        </ul>
      </div>
    </nav>
  )
}
