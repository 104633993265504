import { useEffect } from 'react'
import { createPortal } from 'react-dom'

function Modal({ children, show, onShow, onClose }) {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden'
      if(onShow !== undefined) onShow()
    } else {
      document.body.style.overflow = 'auto'
      if(onClose !== undefined) onClose()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])
  
  return (
    show
    ? createPortal(<>{children}</>, document.body)
    : null)
}

export default Modal