import { createBrowserRouter, RouterProvider, useParams } from 'react-router-dom'

import Home from '../../pages/Home'
import Blog, { BlogPosts, BlogPost, fetchPost, fetchPosts } from '../../pages/blog'
import Error404 from '../../pages/error404/Error404'
import ServerRedirect from './ServerRedirect'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/blog",
    element: <Blog />,
    children: [
      {
        index: true,
        element: <BlogPosts />,
        loader: async({ params }) => fetchPosts()
      },
      {
        path: ":slug",
        element: <BlogPost />,
        loader: async ({ params }) => fetchPost(params.slug)
      },
      {
        path: "category/:category",
        Component: () =>{
          const { category } = useParams()
          return <BlogPosts category={category} />
        },
        loader: async({ params }) => fetchPosts(params.category)
      }
    ]
  },
  {
    path: "/404",
    element: <Error404 />
  },
  {
    path: "*",
    element: <ServerRedirect to="/404" />
  }
])

function AppRoutes() {
  return <RouterProvider router={router} />
}

export default AppRoutes
