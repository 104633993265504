import { useState, useRef, useEffect } from "react"

import Modal from "../Modal"

import useOnClickOutside from "../../hooks/useOnClickOutside"

import { FiX } from 'react-icons/fi'

function PortfolioCarouselItem({ imgSrc, imgAltText, logos, heading, category, text }) {
  const [shouldModalOpen, setShouldModalOpen] = useState(false)
  const hasBeenInteractedWith = useRef(false)
  const modalRef = useRef(null)
  const cardRef = useRef(null)

  function showPortfolioModal() {
    setShouldModalOpen(true)
    if(!hasBeenInteractedWith.current) hasBeenInteractedWith.current = true
  }
  
  function hidePortfolioModal() {
    const modalBackdrop = modalRef.current.parentElement
    modalBackdrop.dataset.closing = true

    const modalClosingDuration = parseInt(getComputedStyle(modalBackdrop).getPropertyValue('--closing-duration'))
    setTimeout(() => {
      setShouldModalOpen(false)
    }, modalClosingDuration)
  }

  useOnClickOutside(modalRef, hidePortfolioModal)
  
  useEffect(() => {
    if(!hasBeenInteractedWith.current) return
    if(shouldModalOpen) {
      const modalBackdrop = modalRef.current.parentElement
      modalBackdrop.dataset.closing = false
      modalRef.current?.focus()
    }
    else {
      cardRef.current?.focus()
    }
  }, [shouldModalOpen])
  
  return (
    <>
      <button className="portfolio-section__carousel-item" onClick={showPortfolioModal} ref={cardRef}>
        <div className="portfolio-section__carousel-item-img-container">
          <img src={imgSrc} alt={imgAltText} className="portfolio-section__carousel-item-img" />
        </div>

        <div className="portfolio-section__carousel-item-content">
          <h3 className="portfolio-section__carousel-item-heading">{heading}</h3>
          <p className="portfolio-section__carousel-item-category">{category}</p>
        </div>
      </button>

      <Modal show={shouldModalOpen}>
        <div className="portfolio-modal-backdrop">
          <div className="portfolio-modal" tabIndex={0} ref={modalRef}>
            <button className="portfolio-modal__close-btn icon-btn" onClick={hidePortfolioModal}>
              <FiX className="icon-btn__icon" />
            </button>

            <div className="portfolio-modal__img-container">
              <img src={imgSrc} alt={imgAltText} className="portfolio-modal__img" />
              <div className="portfolio-modal__img-overlay"></div>
            </div>

            <div className="portfolio-modal__content">
              <h3 className="portfolio-modal__heading">{heading}</h3>
              <div className="portfolio-modal__logos">
                {logos?.map(logo => (
                  <img 
                    src={`/images/logos/${logo}`} 
                    alt={logo.replace(/-logo\..+/, "").replace(/-/g, " ")} 
                    className="portfolio-modal__logo" 
                    key={logo} 
                  />
                ))}
              </div>
              <p className="portfolio-modal__text" dangerouslySetInnerHTML={{__html: text}}></p>
              <p className="portfolio-modal__category">Category: {category}</p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default PortfolioCarouselItem
