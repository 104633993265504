import { useLayoutEffect, useRef } from 'react'

import { gsap } from 'gsap'

import InfoSection from './info-section'
import focusData from '../data/focus.json'

function FocusSection() {
  const focusSectionRef = useRef(null)
  
  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {      
      gsap.utils.toArray(".info-section__sub-section").forEach((subSection, i) => {
        const direction = i % 2 === 0 ? -1 : 1
        
        gsap.from(subSection.querySelector(".info-section__sub-section-img"), {
          scrollTrigger: {
            trigger: subSection.querySelector(".info-section__sub-section-img"),
            start: "top 90%",
            toggleActions: "play none none reverse",
          },
          xPercent: 75 * direction,
          opacity: 0,
          scale: 1.05,
          duration: 0.75,
          ease: "power2",
        })

        gsap.from(subSection.querySelectorAll(".info-section__sub-section-content > *"), {
          scrollTrigger: {
            trigger: subSection.querySelector(".info-section__sub-section-content"),
            start: "top 90%",
            toggleActions: "play none none reverse",
          },
          xPercent: 50 * -direction,
          opacity: 0,
          duration: 1,
          ease: "power4",
          stagger: 0.05,
        })
      })
    }, focusSectionRef.current)

    return () => ctx.revert()
  }, [])
  
  return (
    <InfoSection
      heading={focusData.heading}
      id="focus"
      content={focusData.content}
      imgWidth={564}
      imgHeight={377}
      ref={focusSectionRef}
    />
  )
}

export default FocusSection
