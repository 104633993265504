// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* RESET */
*, *::before, *::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font: inherit;
}

html {
  color-scheme: dark light;
}

body, #root {
  min-height: 100vh;
}

img, picture, svg, video {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

button {
  border: none;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,UAAU;AACV;EACE,sBAAsB;EACtB,UAAU;EACV,SAAS;EACT,aAAa;AACf;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":["/* RESET */\n*, *::before, *::after {\n  box-sizing: border-box;\n  padding: 0;\n  margin: 0;\n  font: inherit;\n}\n\nhtml {\n  color-scheme: dark light;\n}\n\nbody, #root {\n  min-height: 100vh;\n}\n\nimg, picture, svg, video {\n  display: block;\n  max-width: 100%;\n  height: auto;\n}\n\na {\n  text-decoration: none;\n}\n\nli {\n  list-style: none;\n}\n\nbutton {\n  border: none;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
