import { useLayoutEffect } from 'react'

import { gsap } from 'gsap'

import './contact-section.css'

function ContactSection() {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from([
        ".contact-section__heading",
        ".contact-section__text",
        ".contact-section .action-btn"
      ], {
        scrollTrigger: {
          trigger: ".contact-section",
          start: "top bottom",
          toggleActions: "play none none none",
        },
        opacity: 0,
        yPercent: 100,
        duration: 0.3,
        delay: 0.25,
        ease: "ease-in",
        stagger: 0.15,
      })
    })

    return () => ctx.revert()
  }, [])
  
  return (
    <section id="contact" className="contact-section">
      <h2 className="contact-section__heading">CONTACT US</h2>
      <p className="contact-section__text">We look forward to working with you</p>
      <a href="mailto:contact@minohealth.org" className="action-btn">Send us an email</a>
    </section>
  )
}

export default ContactSection