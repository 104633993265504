// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fda-section {
  max-width: var(--max-content-width);
  margin-inline: auto;
  padding-block: 2rem;
  margin-block: 2rem;
}

.fda-section__banner {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/fda-section/fda-section.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".fda-section {\n  max-width: var(--max-content-width);\n  margin-inline: auto;\n  padding-block: 2rem;\n  margin-block: 2rem;\n}\n\n.fda-section__banner {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
