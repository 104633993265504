// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-section {
  display: grid;
  gap: 1em;
  place-items: center;
  text-align: center;
  margin-inline: clamp(1.25em, calc(0.25em + 3vw), 3em);
  margin-block: 2em;
  padding-inline: 2em;
  padding-block: 1.5em;
  border-radius: 1.25em;
  background-color: var(--clr-bg-secondary);
}

@media (min-width: 60rem) {
  .contact-section {
    margin-inline: clamp(3em, calc(1.5em + 4vw), 5em);
    background-color: #0000;
  }
}

.contact-section__heading {
  font-size: clamp(2rem,calc(1rem + 2.75vw),2.75rem);
  font-weight: 600;
  color: var(--clr-text-secondary);
}

.contact-section__text {
  font-size: clamp(1rem, calc(0.75rem + 0.75vw), 1.125rem);
}

@media (min-width: 60rem) {
  .contact-section__text {
    font-size: clamp(1.125rem, calc(0.75rem + 0.75vw), 1.75rem);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/contact-section/contact-section.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,kBAAkB;EAClB,qDAAqD;EACrD,iBAAiB;EACjB,mBAAmB;EACnB,oBAAoB;EACpB,qBAAqB;EACrB,yCAAyC;AAC3C;;AAEA;EACE;IACE,iDAAiD;IACjD,uBAAuB;EACzB;AACF;;AAEA;EACE,kDAAkD;EAClD,gBAAgB;EAChB,gCAAgC;AAClC;;AAEA;EACE,wDAAwD;AAC1D;;AAEA;EACE;IACE,2DAA2D;EAC7D;AACF","sourcesContent":[".contact-section {\n  display: grid;\n  gap: 1em;\n  place-items: center;\n  text-align: center;\n  margin-inline: clamp(1.25em, calc(0.25em + 3vw), 3em);\n  margin-block: 2em;\n  padding-inline: 2em;\n  padding-block: 1.5em;\n  border-radius: 1.25em;\n  background-color: var(--clr-bg-secondary);\n}\n\n@media (min-width: 60rem) {\n  .contact-section {\n    margin-inline: clamp(3em, calc(1.5em + 4vw), 5em);\n    background-color: #0000;\n  }\n}\n\n.contact-section__heading {\n  font-size: clamp(2rem,calc(1rem + 2.75vw),2.75rem);\n  font-weight: 600;\n  color: var(--clr-text-secondary);\n}\n\n.contact-section__text {\n  font-size: clamp(1rem, calc(0.75rem + 0.75vw), 1.125rem);\n}\n\n@media (min-width: 60rem) {\n  .contact-section__text {\n    font-size: clamp(1.125rem, calc(0.75rem + 0.75vw), 1.75rem);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
