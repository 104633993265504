// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.problem-statistics {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
  row-gap: 2rem;
  text-align: center;
  padding-block: 1rem;
}

.problem-statistic {
  display: grid;
  gap: 1rem;
}

.problem-statistic h4 {
  font-size: 1.25rem;
  font-weight: 500;
}

.problem-statistic__value {
  font-size: 5rem;
  font-weight: 600;
  line-height: 1;
}

:where(.info-section__sub-section-img-container) video {
  margin-inline: auto;
}

.fancy-list {
  counter-reset: list-counter;
  /* margin-top: 1rem; */
}

.fancy-list li + li {
  margin-top: 1rem;
}

.fancy-list li {
  counter-increment: list-counter;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.125rem;
}

.fancy-list li::before {
  --_size: 2.5em;
  content: counter(list-counter);
  display: grid;
  place-items: center;
  width: var(--_size);
  height: var(--_size);
  font-size: clamp(1.5rem, calc(0.75rem + 2.5vw), 1.75rem);
  font-weight: 600;
  border-radius: 50%;
  color: var(--clr-bg-primary);
  background-color: var(--clr-text-primary);
}

@media (min-width: 60rem) {
  .fancy-list li::before {
    font-size: clamp(1.75rem, calc(1rem + 1vw), 2.25rem);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/about-section/about-section.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,6BAA6B;EAC7B,SAAS;EACT,aAAa;EACb,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,+BAA+B;EAC/B,aAAa;EACb,+BAA+B;EAC/B,mBAAmB;EACnB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,oBAAoB;EACpB,wDAAwD;EACxD,gBAAgB;EAChB,kBAAkB;EAClB,4BAA4B;EAC5B,yCAAyC;AAC3C;;AAEA;EACE;IACE,oDAAoD;EACtD;AACF","sourcesContent":[".problem-statistics {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n  gap: 1rem;\n  row-gap: 2rem;\n  text-align: center;\n  padding-block: 1rem;\n}\n\n.problem-statistic {\n  display: grid;\n  gap: 1rem;\n}\n\n.problem-statistic h4 {\n  font-size: 1.25rem;\n  font-weight: 500;\n}\n\n.problem-statistic__value {\n  font-size: 5rem;\n  font-weight: 600;\n  line-height: 1;\n}\n\n:where(.info-section__sub-section-img-container) video {\n  margin-inline: auto;\n}\n\n.fancy-list {\n  counter-reset: list-counter;\n  /* margin-top: 1rem; */\n}\n\n.fancy-list li + li {\n  margin-top: 1rem;\n}\n\n.fancy-list li {\n  counter-increment: list-counter;\n  display: grid;\n  grid-template-columns: auto 1fr;\n  align-items: center;\n  gap: 0.5rem;\n  font-size: 1.125rem;\n}\n\n.fancy-list li::before {\n  --_size: 2.5em;\n  content: counter(list-counter);\n  display: grid;\n  place-items: center;\n  width: var(--_size);\n  height: var(--_size);\n  font-size: clamp(1.5rem, calc(0.75rem + 2.5vw), 1.75rem);\n  font-weight: 600;\n  border-radius: 50%;\n  color: var(--clr-bg-primary);\n  background-color: var(--clr-text-primary);\n}\n\n@media (min-width: 60rem) {\n  .fancy-list li::before {\n    font-size: clamp(1.75rem, calc(1rem + 1vw), 2.25rem);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
