import { useLayoutEffect, useRef } from "react";
import { ComposableInfoSection as InfoSection } from "../info-section";
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'
import { throttle } from '../../utils/rate-limiters'

import productData from "../../data/product.json"

export default function ProductSection() {
  const productSectionRef = useRef(null)

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const mm = gsap.matchMedia()
      ScrollTrigger.saveStyles(".info-section__sub-section-content .fancy-list, .info-section__sub-section-content")

      gsap.utils.toArray(".fancy-list").forEach((list, i) => {
        const tl = gsap.timeline({
          paused: true,
        })

        Array.from(list.children).forEach((item, j) => {
          tl.from(item, {
            yPercent: 15,
            opacity: 0,
            duration: 1,
            ease: "power4",
          }, j + 0.1)
        })

        mm.add({isDesktop: "(min-width: 60rem)", isTablet: "(max-width: 59.99rem)"}, mmCtx => {
          const { isDesktop } = mmCtx.conditions

          ScrollTrigger.create({
            trigger: list.parentElement,
            start: isDesktop? "center 55%": "top 75%",
            end: isDesktop? undefined: "bottom 75%",
            pin: isDesktop ? list.closest(".info-section__sub-section"): false,
            toggleActions: "play none none reverse",
            scrub: 0.25,
            onUpdate: ({ progress }) => {
              if(tl.progress() >= progress) return
              tl.progress(progress)
            }
          })
        })
      })

      return () => {
        mm.revert()
      }
    }, productSectionRef.current)

    return () => ctx.revert()
  }, [])

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const eventListenerMap = new Map()
      const mm = gsap.matchMedia()
      ScrollTrigger.saveStyles(".info-section__sub-section-content video")

      gsap.utils.toArray("video").forEach(video => {
        const throttleUpdateVideoProgress = throttle((progress) => {
          video.currentTime = progress * video.duration
        }, 1000/60)

        function setupVideoProgressUpdate() {
          mm.add({isDesktop: "(min-width: 60rem)", isTablet: "(max-width: 59.99rem)"}, mmCtx => {
            const { isDesktop } = mmCtx.conditions

            gsap.to(video, {
              scrollTrigger: {
                trigger: isDesktop
                  ? video.closest(".info-section__sub-section").querySelector(".info-section__sub-section-content")
                  : video,
                start: "center 55%",
                pin: isDesktop ? false: video.closest(".info-section__sub-section"),
                toggleActions: "play none none reverse",
                scrub: 2,
              },
              ease: "power4",
              onUpdate: function (){
                throttleUpdateVideoProgress(this.progress())
              }
            })
          })

          ScrollTrigger.refresh()
        }

        eventListenerMap.set(video, setupVideoProgressUpdate)
      })

      for(const [video, listener] of eventListenerMap.entries()) {
        video.addEventListener("loadeddata", listener, { once: true })
      }

      return () => {
        for(const [video, listener] of eventListenerMap.entries()) {
          video.removeEventListener("loadeddata", listener)
        }
        mm.revert()
      }
    }, productSectionRef.current)

    return () => ctx.revert()
  }, [])

  return (
    <InfoSection>
      <InfoSection.Content>
      <InfoSection.SubSection>
          <InfoSection.SubSection.ImageContainer>
            <video
              id="usage-video"
              width={400}
              onLoad={e => e.target.pause()}
            >
              <source src={`/videos/${productData.content.usage.vid.mov}`} type="video/quicktime" />
              <source src={`/videos/${productData.content.usage.vid.webm}`} type="video/webm" />
            </video>
          </InfoSection.SubSection.ImageContainer>
          
          <InfoSection.SubSection.Content>
            <InfoSection.SubSection.Heading>{productData.content.usage.heading.toUpperCase()}</InfoSection.SubSection.Heading>

            <ol className="fancy-list">
              {productData.content.usage.steps.map((step, index) => (
                <li key={index}>{step}</li>
              ))}
            </ol>
          </InfoSection.SubSection.Content>
        </InfoSection.SubSection>

        <InfoSection.SubSection>
          <InfoSection.SubSection.ImageContainer>
            <video
              id="benefits-video"
              width={275}
              onLoad={e => e.target.pause()}
            >
              <source src={`/videos/${productData.content.benefits.vid.mov}`} type="video/quicktime" />
              <source src={`/videos/${productData.content.benefits.vid.webm}`} type="video/webm" />
            </video>
          </InfoSection.SubSection.ImageContainer>
          
          <InfoSection.SubSection.Content>
            <InfoSection.SubSection.Heading>{productData.content.benefits.heading.toUpperCase()}</InfoSection.SubSection.Heading>

            <ol className="fancy-list">
              {productData.content.benefits.list.map((step, index) => (
                <li key={index}>{step}</li>
              ))}
            </ol>
          </InfoSection.SubSection.Content>
        </InfoSection.SubSection>
      </InfoSection.Content>
    </InfoSection>
  )
}