import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { THEME, darkThemeMediaQuery } from '../utils/theme'

const useAppStore = create(
  persist(
    (set, get) => ({
      theme: THEME.SYSTEM,
      toggleTheme: () => set({
        theme: get().theme === THEME.SYSTEM
          ? darkThemeMediaQuery.matches ? THEME.LIGHT : THEME.DARK
          : get().theme === THEME.DARK ? THEME.LIGHT : THEME.DARK
      })
    }),
    {
      name: "mino-storage"
    }
  )
)

export default useAppStore