// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.moremi-chart-wrapper {
  --chart-tooltip-border-radius: 0.3125rem;
}

:where(:root[data-theme="light"]) .moremi-chart-wrapper {
  --chart-axis-color: #666;
  --chart-axis-value-color: #787878;
  --chart-grid-color: #bbb;

  --chart-tooltip-cursor-color: #3332;
  --chart-tooltip-background-color: #fff;
  --chart-tooltip-border-color: #ccc;
}

:where(:root[data-theme="dark"]) .moremi-chart-wrapper {
  --chart-axis-color: #ccc;
  --chart-axis-value-color: #888;
  --chart-grid-color: #444;

  --chart-tooltip-cursor-color: #ccc3;
  --chart-tooltip-background-color: #1b1b1b;
  --chart-tooltip-border-color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/pages/blog/interactive-components/moremi-charts/moremi-charts.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;;AAEA;EACE,wBAAwB;EACxB,iCAAiC;EACjC,wBAAwB;;EAExB,mCAAmC;EACnC,sCAAsC;EACtC,kCAAkC;AACpC;;AAEA;EACE,wBAAwB;EACxB,8BAA8B;EAC9B,wBAAwB;;EAExB,mCAAmC;EACnC,yCAAyC;EACzC,kCAAkC;AACpC","sourcesContent":[".moremi-chart-wrapper {\n  --chart-tooltip-border-radius: 0.3125rem;\n}\n\n:where(:root[data-theme=\"light\"]) .moremi-chart-wrapper {\n  --chart-axis-color: #666;\n  --chart-axis-value-color: #787878;\n  --chart-grid-color: #bbb;\n\n  --chart-tooltip-cursor-color: #3332;\n  --chart-tooltip-background-color: #fff;\n  --chart-tooltip-border-color: #ccc;\n}\n\n:where(:root[data-theme=\"dark\"]) .moremi-chart-wrapper {\n  --chart-axis-color: #ccc;\n  --chart-axis-value-color: #888;\n  --chart-grid-color: #444;\n\n  --chart-tooltip-cursor-color: #ccc3;\n  --chart-tooltip-background-color: #1b1b1b;\n  --chart-tooltip-border-color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
