import { forwardRef } from 'react'
import clsx from '../../utils/clsx'

import './info-section.css'

const ComposableInfoSection_ = forwardRef(({ children, className, ...resOfProps }, ref) => {
  return (
    <section className={clsx("info-section", className)} ref={ref} {...resOfProps}>
      {children}
    </section>
  )
})

const ComposableInfoSection = Object.assign(ComposableInfoSection_, {
  Heading: ComposableInfoSectionHeading,
  SubHeading: ComposableInfoSectionSubHeading,
  Content: ComposableInfoSectionContent,
  SubSection: ComposableInfoSectionSubSection,
})

export default ComposableInfoSection

function ComposableInfoSectionHeading({ children, className, ...resOfProps }) {
  return (
    <h2 className={clsx("info-section__heading", className)} {...resOfProps}>
      {children.toUpperCase()}
    </h2>
  )
}

function ComposableInfoSectionSubHeading({ children, className, ...resOfProps }) {
  return (
    <p className={clsx("info-section__sub-heading", className)} {...resOfProps}>
      {children.toUpperCase()}
    </p>
  )
}

function ComposableInfoSectionContent({ children, className, ...resOfProps }) {
  return (
    <div className={clsx("info-section__content", className)} {...resOfProps}>
      {children}
    </div>
  )
}

function ComposableInfoSectionSubSection({ children, className, ...resOfProps }) {
  return (
    <section className={clsx("info-section__sub-section", className)} {...resOfProps}>
      {children}
    </section>
  )
}

ComposableInfoSectionSubSection.ImageContainer = ComposableInfoSectionSubSectionImageContainer
ComposableInfoSectionSubSection.Image = ComposableInfoSectionSubSectionImage
ComposableInfoSectionSubSection.Content = ComposableInfoSectionSubSectionContent
ComposableInfoSectionSubSection.Heading = ComposableInfoSectionSubSectionHeading
ComposableInfoSectionSubSection.Text = ComposableInfoSectionSubSectionText

function ComposableInfoSectionSubSectionImageContainer({ children, className, ...resOfProps }) {
  return (
    <div className={clsx("info-section__sub-section-img-container", className)} {...resOfProps}>
      {children}
    </div>
  )
}

function ComposableInfoSectionSubSectionImage({ className, alt, ...resOfProps }) {
  return (
    <img
      className={clsx("info-section__sub-section-img", className)}
      alt={alt ?? resOfProps.src.replace(/\..+/, "").replace(/-/g, " ")}
      {...resOfProps} 
    />
  )
}

function ComposableInfoSectionSubSectionContent({ children, className, ...resOfProps }) {
  return (
    <div className={clsx("info-section__sub-section-content", className)} {...resOfProps}>
      {children}
    </div>
  )
}

function ComposableInfoSectionSubSectionHeading({ children, className, ...resOfProps }) {
  return (
    <h3 className={clsx("info-section__sub-section-heading", className)} {...resOfProps}>
      {children.toUpperCase()}
    </h3>
  )
}

function ComposableInfoSectionSubSectionText({ children, className, ...resOfProps }) {
  return (
    <p className={clsx("info-section__sub-section-text", className)} {...resOfProps}>
      {children}
    </p>
  )
}