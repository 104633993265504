import { useEffect, useState } from 'react'
import { shallow } from "zustand/shallow"
import useAppStore from '../stores/useAppStore'

import { darkThemeMediaQuery, THEME } from '../utils/theme'

function useTheme() {
  const [theme, toggleTheme] = useAppStore(state => [state.theme, state.toggleTheme], shallow)

  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    if(theme === THEME.SYSTEM) return darkThemeMediaQuery.matches
    else return theme === THEME.DARK
  })

  useEffect(() => {
    if(theme !== THEME.SYSTEM) {
      setIsDarkTheme(theme === THEME.DARK)
      return
    }

    function handleDarkThemeChange(e){
      setIsDarkTheme(e.matches)
    }

    darkThemeMediaQuery.addEventListener("change", handleDarkThemeChange)

    return () => {
      darkThemeMediaQuery.removeEventListener("change", handleDarkThemeChange)
    }
  }, [theme])

  
  return {
    isDarkTheme,
    theme,
    toggleTheme
  }
}

export default useTheme