import { useLayoutEffect, useRef } from 'react'

import { ComposableInfoSection as InfoSection } from '../info-section'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'

import aboutData from '../../data/about.json'

import './about-section.css'

function AboutSection() {
  const aboutSectionRef = useRef(null)
  
  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {      
      gsap.utils.toArray(".info-section__sub-section").forEach((subSection, i) => {
        const direction = i % 2 === 0 ? -1 : 1
        
        gsap.from(subSection.querySelector(".info-section__sub-section-img"), {
          scrollTrigger: {
            trigger: subSection,
            start: "top 90%",
            toggleActions: "play none none reverse",
          },
          xPercent: 10 * direction,
          opacity: 0,
          duration: 0.75,
          ease: "power4",
        })

        gsap.from(subSection.querySelectorAll(".info-section__sub-section-content > *"), {
          scrollTrigger: {
            trigger: subSection,
            start: "top 90%",
            toggleActions: "play none none reverse",
          },
          yPercent: 15,
          opacity: 0,
          duration: 1,
          ease: "power4",
          stagger: 0.1,
        })
      })
    }, aboutSectionRef.current)

    return () => ctx.revert()
  }, [])

  return (
    <InfoSection id="about" ref={aboutSectionRef}>
      <InfoSection.Heading>{aboutData.heading.toUpperCase()}</InfoSection.Heading>
      <InfoSection.SubHeading>{aboutData.subHeading.toUpperCase()}</InfoSection.SubHeading>

      <InfoSection.Content>
        <InfoSection.SubSection>
          <InfoSection.SubSection.ImageContainer>
            <InfoSection.SubSection.Image
              src={`/images/${aboutData.content.problem.img}`}
              alt={getAltTextFromImgName(aboutData.content.problem.img)}
            />
          </InfoSection.SubSection.ImageContainer>
          
          <InfoSection.SubSection.Content>
            <InfoSection.SubSection.Heading>{aboutData.content.problem.heading.toUpperCase()}</InfoSection.SubSection.Heading>
            <InfoSection.SubSection.Text>{aboutData.content.problem.text}</InfoSection.SubSection.Text>

            <div className="problem-statistics">
              {aboutData.content.problem.extra.ratios.map(ratio => (
                <div className="problem-statistic" key={ratio.heading.toLowerCase().replace(/\s/g, "-")}>
                  <h4>{ratio.heading}</h4>
                  {ratio.data.map(({country, value}) => (
                    <ProblemStatisticValueAndLabel country={country} value={value} key={country} />
                  ))}
                </div>
              ))}
            </div>
          </InfoSection.SubSection.Content>
        </InfoSection.SubSection>

        <InfoSection.SubSection>
          <InfoSection.SubSection.ImageContainer>
            <InfoSection.SubSection.Image
              src={`/images/${aboutData.content.solution.img}`}
              alt={getAltTextFromImgName(aboutData.content.solution.img)}
            />
          </InfoSection.SubSection.ImageContainer>
          
          <InfoSection.SubSection.Content>
            <InfoSection.SubSection.Heading>{aboutData.content.solution.heading.toUpperCase()}</InfoSection.SubSection.Heading>
            <InfoSection.SubSection.Text>{aboutData.content.solution.text}</InfoSection.SubSection.Text>
          </InfoSection.SubSection.Content>
        </InfoSection.SubSection>

        <InfoSection.SubSection>
          <InfoSection.SubSection.ImageContainer>
            <InfoSection.SubSection.Image
              src={`/images/${aboutData.content.research.img}`}
              alt={getAltTextFromImgName(aboutData.content.research.img)}
            />
          </InfoSection.SubSection.ImageContainer>
          
          <InfoSection.SubSection.Content>
            <InfoSection.SubSection.Heading>{aboutData.content.research.heading.toUpperCase()}</InfoSection.SubSection.Heading>
            <InfoSection.SubSection.Text>{aboutData.content.research.text}</InfoSection.SubSection.Text>
          </InfoSection.SubSection.Content>
        </InfoSection.SubSection>
      </InfoSection.Content>
    </InfoSection>
  )
}

export default AboutSection

function getAltTextFromImgName(imgName) {
  return imgName.replace(/\..+/, "").replace(/-/g, " ")
}

function ProblemStatisticValueAndLabel({ country, value }) {
  const selfRef = useRef(null)
  const [antecedent, consequent] = value.split(":")

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(".problem-statistic__value > span", {
        scrollTrigger: {
          trigger: ".problem-statistic__value",
          toggleAttribute: "play none none none",
        },
        textContent: consequent,
        snap: {
          textContent: 1
        },
        duration: 1.5 + Math.min(consequent/50_000, 2),
        ease: "power4",
        onUpdate: function(){
          this.targets()[0].textContent = compactNumberFormatter.format(this.targets()[0].textContent).replace(/\.\d+/, "")
        }       
      })
    }, selfRef.current)

    return () => ctx.revert()
  }, [consequent])

  return (
    <div ref={selfRef}>
      <p className="problem-statistic__value">1:<span>1</span></p>
      <p className="problem-statistic__label">{country}</p>
    </div>
  )
}

const compactNumberFormatter = new Intl.NumberFormat('en-US', {notation: "compact"})
