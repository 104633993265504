import { Helmet } from 'react-helmet'
import Header from '../components/header'
import HeroSection from '../components/hero-section/HeroSection'
import FdaSection from '../components/fda-section'
import ProductSection from '../components/product-section/ProductSection'
import FocusSection from '../components/FocusSection'
import PortfolioSection from '../components/portfolio-section'
import AboutSection from '../components/about-section'
import ContactSection from '../components/contact-section'
import Footer from '../components/footer'

function Home() { 
  return (
    <>
      <Helmet>
        <link rel="preload" href="/images/fda-light.gif" as="image" />
        <link rel="preload" href="/images/fda-dark.gif" as="image" />
      </Helmet>

      <Header />

      <main className="main-content">
        <HeroSection />
        <FdaSection />
        <ProductSection />
        <FocusSection />
        <PortfolioSection />
        <AboutSection />
        <ContactSection />
      </main>

      <Footer />
    </>
  )
}

export default Home;
