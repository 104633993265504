// @ts-check
"use strict";

import React from "react"

import { FaUser } from "react-icons/fa";
import "./moremi-qa-block.css"

export default function QABlock({ children }) {
  return (
    <div className="moremi-qa-block">
      {children}
    </div>
  )
}

QABlock.Question = QABlockQuestion
QABlock.Answer = QABlockAnswer

function QABlockQuestion({ children }) {
  return (
    <QABlockItem data-item-type="question">
      <QABlockItemHeader>
        <QABlockItemIcon>
          <FaUser />
        </QABlockItemIcon>
        <QABlockItemHeading>Question</QABlockItemHeading>
      </QABlockItemHeader>

      <QABlockItemContent>{children}</QABlockItemContent>
    </QABlockItem>
  )
}

function QABlockAnswer({ children }) {
  return (
    <QABlockItem data-item-type="answer">
      <QABlockItemHeader>
        <QABlockItemIcon>
          <img src="/images/logos/minohealth-logo.png" alt="moremi" />
        </QABlockItemIcon>
        <QABlockItemHeading>Moremi AI</QABlockItemHeading>
      </QABlockItemHeader>

      <QABlockItemContent>{children}</QABlockItemContent>
    </QABlockItem>
  )
}

/** @param {React.ComponentProps<"div">} props */
function QABlockItem(props) {
  return <div className="moremi-qa-block__item" {...props} />
}

/** @param {React.ComponentProps<"div">} props */
function QABlockItemHeader(props) {
  return <div className="moremi-qa-block__item-header" {...props} />
}

function QABlockItemHeading({ children }) {
  return <strong className="moremi-qa-block__item-heading">{children}</strong>
}

/** @param {React.ComponentProps<"div">} props */
function QABlockItemIcon(props) {
  return <div className="moremi-qa-block__item-icon" {...props} />
}

/** @param {React.ComponentProps<"div">} props */
function QABlockItemContent(props) {
  return <div className="moremi-qa-block__item-content" {...props} />
}
