// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-post-card {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.blog-post-card__image {
  --size: 11.25rem;
  width: var(--size);
  height: var(--size);
  object-fit: cover;
}

.blog-post-card__content {
  display: grid;
  gap: 0.25rem;
}

.blog-post-card__category {
  font-weight: 600;
}

.blog-post-card__title {
  font-size: 1.5rem;
  font-weight: 500;
}

:where([data-theme="light"]) .blog-post-card__title {
  color: var(--blog-pink-accent-color)
}

:where([data-theme="dark"]) .blog-post-card__title {
  color: var(--blog-cyan-accent-color)
}

:where(.blog-post-card__title) a {
  color: inherit;
}

.blog-post-card__excerpt {
  --lines: 3;
  /* font-size: 1.125rem; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: var(--lines);
  line-clamp: var(--lines); 
  -webkit-box-orient: vertical;
}`, "",{"version":3,"sources":["webpack://./src/pages/blog/blog-post-card.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,yBAAyB;EACzB,gBAAgB;EAChB,oBAAoB;EACpB,gCAAgC;EAChC,wBAAwB;EACxB,4BAA4B;AAC9B","sourcesContent":[".blog-post-card {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n}\n\n.blog-post-card__image {\n  --size: 11.25rem;\n  width: var(--size);\n  height: var(--size);\n  object-fit: cover;\n}\n\n.blog-post-card__content {\n  display: grid;\n  gap: 0.25rem;\n}\n\n.blog-post-card__category {\n  font-weight: 600;\n}\n\n.blog-post-card__title {\n  font-size: 1.5rem;\n  font-weight: 500;\n}\n\n:where([data-theme=\"light\"]) .blog-post-card__title {\n  color: var(--blog-pink-accent-color)\n}\n\n:where([data-theme=\"dark\"]) .blog-post-card__title {\n  color: var(--blog-cyan-accent-color)\n}\n\n:where(.blog-post-card__title) a {\n  color: inherit;\n}\n\n.blog-post-card__excerpt {\n  --lines: 3;\n  /* font-size: 1.125rem; */\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-line-clamp: var(--lines);\n  line-clamp: var(--lines); \n  -webkit-box-orient: vertical;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
