import { useCallback, useEffect, useRef, useState } from 'react'

import useToggledState from '../../hooks/useToggledState'
import useOnClickOutside from '../../hooks/useOnClickOutside'

import NavLink from './NavLink'

import { FiMenu, FiX } from 'react-icons/fi'

import './nav.css'

function Nav() {
  const [navMenuState, setNavMenuState] = useState(NAV_MENU_STATE.CLOSED)
  const [isMenuExpanded, toggleIsMenuExpanded] = useToggledState(false)

  const mainNavRef = useRef(null)

  const handleMenuToggle = useCallback(() => {
    if(isMenuExpanded) setNavMenuState(NAV_MENU_STATE.CLOSING)
    toggleIsMenuExpanded()
  }, [isMenuExpanded, toggleIsMenuExpanded])
  
  useEffect(() => {
    if(isMenuExpanded) setNavMenuState(NAV_MENU_STATE.OPEN)

    else {
      const navList = mainNavRef.current.querySelector('ul')
      const navListClosingDuration = parseFloat(getComputedStyle(navList).getPropertyValue('--closing-duration'))

      const timeout = setTimeout(() => {
        setNavMenuState(NAV_MENU_STATE.CLOSED)
      }, navListClosingDuration)

      return () => clearTimeout(timeout)
    }
  }, [isMenuExpanded])

  useOnClickOutside(mainNavRef, () => {
    if(isMenuExpanded) handleMenuToggle()
  })

  useEffect(() => {
    if(mainNavRef.current == null) return
    const navLinks = mainNavRef.current.querySelectorAll('a')
    navLinks.forEach(link => {
      link.addEventListener('click', handleMenuToggle)
    })

    return () => {
      navLinks.forEach(link => {
        link.removeEventListener('click', handleMenuToggle)
      })
    }

  }, [mainNavRef, handleMenuToggle])

  return (
    <nav className="main-nav" ref={mainNavRef}>
      <button
        type="button"
        className="main-nav__toggle-btn icon-btn"
        aria-expanded={isMenuExpanded}
        aria-controls="main-nav-list"
        onClick={handleMenuToggle}
      >
        {
          isMenuExpanded
            ? <FiX className="icon-btn__icon" />
            : <FiMenu className="icon-btn__icon" />
        }
        <span className="sr-only">Navigation menu</span>
      </button>

      <ul
        id="main-nav-list"
        className="main-nav__nav-list"
        data-state={navMenuState}
      >
        <NavLink href="#focus" className="main-nav__nav-link" style={{ "--order": 0 }}>Focus</NavLink>
        <NavLink href="#portfolio" className="main-nav__nav-link" style={{ "--order": 1 }}>Portfolio</NavLink>
        <NavLink href="#about" className="main-nav__nav-link" style={{ "--order": 2 }}>About</NavLink>
        <NavLink href="#contact" className="main-nav__nav-link" style={{ "--order": 3 }}>Contact</NavLink>
        <NavLink href="/blog" target="_blank" className="main-nav__nav-link main-nav__nav-link--emphasis" style={{ "--order": 4 }}>Blog</NavLink>
      </ul>
    </nav>
  )
}

const NAV_MENU_STATE = {
  OPEN: 'open',
  CLOSING: 'closing',
  CLOSED: 'closed',
};


export default Nav