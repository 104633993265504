// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page {
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
  min-height: 100dvh;
}

.error-page__content {
  width: 100%;
  max-width: var(--max-content-width);
  padding-bottom: 0em!important;
  
  display: grid;
  place-content: center;
  justify-items: center;
  gap: 0.5em;
  padding: 1em;
}

.error-page__title {
  font-size: 7.5rem;
  font-weight: 700;
  text-align: center;
  color: var(--clr-text-primary);
  line-height: 1em;
  display: flex;
  align-items: center;
}

.error-page__logo {
  display: inline-block;
  width: 1.5ch;
  height: 1em;
}

@media (min-width: 30rem) {
  .error-page__content {
    font-size: 1.5rem;
  }
  
  .error-page__title {
    font-size: 15rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/error404/error404.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,4BAA4B;EAC5B,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,mCAAmC;EACnC,6BAA6B;;EAE7B,aAAa;EACb,qBAAqB;EACrB,qBAAqB;EACrB,UAAU;EACV,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,8BAA8B;EAC9B,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".error-page {\n  display: grid;\n  grid-template-rows: auto 1fr;\n  min-height: 100vh;\n  min-height: 100dvh;\n}\n\n.error-page__content {\n  width: 100%;\n  max-width: var(--max-content-width);\n  padding-bottom: 0em!important;\n  \n  display: grid;\n  place-content: center;\n  justify-items: center;\n  gap: 0.5em;\n  padding: 1em;\n}\n\n.error-page__title {\n  font-size: 7.5rem;\n  font-weight: 700;\n  text-align: center;\n  color: var(--clr-text-primary);\n  line-height: 1em;\n  display: flex;\n  align-items: center;\n}\n\n.error-page__logo {\n  display: inline-block;\n  width: 1.5ch;\n  height: 1em;\n}\n\n@media (min-width: 30rem) {\n  .error-page__content {\n    font-size: 1.5rem;\n  }\n  \n  .error-page__title {\n    font-size: 15rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
