import useTheme from "../../hooks/useTheme"

import "./fda-section.css"

export default function FdaSection() {
  const { isDarkTheme } = useTheme()

  const variant = isDarkTheme ? "dark" : "light"

  return (
    <div className="fda-section">
      <img 
        src={`/images/fda-${variant}.gif`}
        alt={`Fda Ghana Approved`}
        className="fda-section__banner"
        width={1920}
        height={420}
        key={variant}
      />
    </div>
  )
}
