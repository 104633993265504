export default function clsx(...classes) {
  let className = ""

  for(const c of classes) {
    if(typeof c === "boolean") continue
    if(typeof c === "string") {
      className += ` ${c}`
    }

    if(typeof c === "object") {
      for(const [key, value] of Object.entries(c)) {
        if(!value) continue
        className += ` ${key}`
      }
    }
  }

  return className.trim()
}