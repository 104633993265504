import React from 'react'

function SplitText({ children, type = "lines", splitProps }) {
  if(type === "words") {
    const words = children.split(" ")
    const numWords = words.length

    return words.map((word, idx) => (
      <span 
        key={`${word}-${idx}`}
        {...splitProps}
      >{word}{idx !== numWords - 1 ? " ": ""}</span>
    ))
  }
  else return children
}

export default SplitText