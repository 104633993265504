import { Link } from "react-router-dom"
import Header from "../../components/header/Header"

import MinohealthLogo from "../../components/MinohealthLogo"

import './error404.css'

function Error404() {
  return (
    <div className="error-page">
      <Header shouldShowNav={false} />
      <main className="error-page__content main-content">
        <h1 className="error-page__title">
          <span>4</span>
          <MinohealthLogo className="error-page__logo" />
          <span>4</span>
        </h1>
        <p>Requested page not found</p>
        <Link to="/" className="action-btn">Go back to home</Link>
      </main>
    </div>
  )
}

export default Error404