import React from "react";

function MinohealthLogo({ color = "currentColor", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2140"
      height="1970"
      fill="none"
      viewBox="0 0 2140 1970"
      {...props}
    >
      <path
        fill={color}
        d="M581.545 734.183l-126.522-73.701c-491.125-24.25-638.3 656.878-172.788 813.038L409 1400.25c123.405-280.91 394.012-331.58 172.572-666.095l-.027.028zm-192.737 22.433l146.633 56.367c12.63 24.358 23.472 49.124 31.251 75.192L253.45 806.879c41.957-24.033 88.468-39.386 135.358-50.263zm-73.262 625.194c-29.923-9.44-58.572-22.41-85.188-39.85l140.155-53.98c-20.627 30-39.166 60.76-54.967 93.83zm140.155-203.47l-308.634 79.86c-18.675-29.22-33.989-63.59-42.337-103.16l416.562-58.38c-18.891 25.85-41.089 52.52-65.591 81.68zm111.614-172.79l-460.986-13.077c10.002-37.352 26.671-72.588 50.278-102.969l418.866 59.568c-.298 18.771-2.629 38.41-8.158 56.478z"
      ></path>
      <path
        fill={color}
        d="M874.703 1375.46c-152.867-22.89-309.176 47.06-465.729 24.79l-126.766 73.27c-92.831 483.27 550.592 692.68 785.042 258.02l.7-146.48c-53.44-77.61-94.887-182.18-193.247-209.6zm-501.669 238.98c-6.424-41.32-4.174-83.47 5.15-124.08 48.082 3.96 97.981 1.17 145.277-4.12l-150.427 128.2zm40.981 108.09l239.41-256.25c34.449-6 65.755-10.58 95.054-12.45l-259.548 350.62c-31.929-24.68-56.35-52.97-74.916-81.89v-.03zm171.596 135.68l231.116-400.67c18.756 3.88 36.862 10.09 53.964 19.15l-172.924 398.4c-37.891 1.79-76.162-3.88-112.156-16.85v-.03zm346.85-99.38c-33.88 33.17-71.771 62.96-114.568 83.95l101.26-331.34c18.295 17.82 33.907 38.11 48.245 59.49l-34.937 187.9z"
      ></path>
      <path
        fill={color}
        d="M1857.22 1475.06l-126.73-73.29c-304.38 33.01-483.32-175.56-662.54 183.26l-.7 146.48c224.18 438.51 886.84 224.76 789.97-256.47v.02zM1183.53 1741l-24.32-155.34c14.86-22.74 30.71-44.9 49.33-64.43l86.11 312.19c-41.68-24.49-78.44-56.96-111.12-92.44v.02zm231.25 134.82l-158.32-392.67c16.37-9.12 34.45-16.82 52.72-21.21l219.95 405.63c-38.16 10.45-75.89 13.4-114.35 8.25zm255.48-87.78l-258.71-331.88c34.45 3.23 69.01 10.06 103.35 15.79l223.2 227.8c-16.75 33.44-40.38 62.66-67.84 88.26v.03zm98.77-202.28l-116.69-94.64c36.13 3.04 72.53 3.72 108.77.9 6.78 30.68 9.92 62.25 7.92 93.72v.02z"
      ></path>
      <path
        fill={color}
        d="M2123.22 959.054c-51.6-196.145-246.13-286.881-435.23-293.499l-127.01 72.886c-41.42 87.453-114.87 179.464-82.37 280.699 61.58 140.24 193.25 239.14 251.87 382.63l126.74 73.3c217.76-55.31 327.04-309.29 266-516.016zm-524.27-126.677l180.02-63.745c45.84 12.641 90.45 30.815 130.05 57.425l-337.42 77.58c6.18-24.658 16.21-48.149 27.32-71.26h.03zm-33.28 130.421l431.3-48.528c20.19 32.035 34.67 68.058 41.17 105.63l-462.26-.63c-5.8-18.2-9.78-37.267-10.21-56.472zm258.22 420.532c-21.12-44.22-47.41-84.93-76.49-123.86l186.31 65.92c-32.28 26.61-70.12 45.46-109.82 57.94zm182.79-147.46l-341.54-78.91c-20.58-24.76-40.71-49.8-58.33-76.41l433.18 49.75c-5.02 36.78-16.4 72.86-33.31 105.57z"
      ></path>
      <path
        fill={color}
        d="M1071.26 109.207l-.3 146.506c180.71 247.983 90.99 506.436 490.04 482.755l127.01-72.886c266.92-414.073-248.19-880.906-616.72-556.375h-.03zm116.01 169.536c-15.48-32.904-32.99-64.478-53.51-94.804 23.01-20.751 48.63-39.577 77.01-53.655l-23.5 148.432v.027zm90.5 226.255c-14.1-31.873-26.13-64.803-38.08-97.707l84.99-307.496c37-1.6 74.73 3.119 110.24 14.756l-157.13 390.447h-.02zm55.86 85.419l241.28-393.268c27.7 27.125 49.54 59.486 64.37 95.021l-261.04 333.239c-16.37-9.44-31.49-21.375-44.61-34.992zm304.16-30.246l-122.29 98.711c-27.16-1.682-54.27-4.422-80.48-10.769l227.33-230.46c-.33 48.528-10.14 96.432-24.59 142.518h.03zM455.024 660.482l126.521 73.7c88.82-7.215 189.159 7.758 263.695-49.45 49.384-39.793 70.498-102.318 93.753-159.417 36.211-91.956 72.827-187.628 131.947-269.602l.29-146.506c-371.457-322.306-874.996 131.18-616.206 551.275zm188.671-6.185L498.661 529.953c-11.519-46.005-18.404-93.773-15.016-141.297l235.589 253.869c-25.017 6.917-48.896 10.226-75.566 11.772h.027zm130.126-36.185L516.035 268.679c16.994-33.88 41.252-63.854 70.308-88.619l231.306 400.51c-12.658 14.485-27.809 26.936-43.828 37.515v.027zm81.204-95.13L680.909 122.933c34.774-13.671 71.202-22.569 108.362-23.898l102.453 335.49c-11.302 29.839-22.361 60.192-36.726 88.457h.027zm84.294-211.065L903.46 117.481c39.816 14.919 74.726 38.518 104.97 66.322-27.588 40.309-49.976 83.493-69.111 128.142v-.028z"
      ></path>
    </svg>
  );
}

export default MinohealthLogo;
