// @ts-check
"use strict";

import React, { createContext, useContext, useId, useLayoutEffect, useState } from "react";

import { FaChevronDown } from "react-icons/fa";
import "./moremi-use-cases.css";

/**
  * @param {Object} props
  * @param {React.ReactNode} props.children
  */
export default function UseCases({ children }) {
  const [selectedUseCase, setSelectedUseCase] = useState("");

  /** @param {string} useCase */
  function selectUseCase(useCase) {
    setSelectedUseCase(useCase);
  }

  return (
    <useCaseContext.Provider value={{ selectedUseCase,  selectUseCase }}>
      {children}
    </useCaseContext.Provider>
  )
}

UseCases.Select = UseCasesSelect
UseCases.Panel = UseCasePanel

/**
  * @param {Object} props
  * @param {string[]} props.useCases
  * @param {string} [props.defaultSelectedUseCase]
  */
function UseCasesSelect({ useCases, defaultSelectedUseCase }) {
  const { selectUseCase } = useContext(useCaseContext)
  const selectInputId = useId()

  useLayoutEffect(() => {
    if(useCases.length === 0) return
    if(defaultSelectedUseCase == null) selectUseCase(useCases[0])
    else if(!useCases.includes(defaultSelectedUseCase)) selectUseCase(useCases[0])
    else selectUseCase(defaultSelectedUseCase)
  }, [])

  /** @param {React.ChangeEvent<HTMLSelectElement>} ev */
  function handleSelect(ev) {
    // @ts-ignore
    selectUseCase(ev.target.value)
  }

  return (
    <div className="moremi-use-cases-select">
      <label htmlFor={selectInputId} className="moremi-use-cases-select__label">Use Cases</label>
      <div className="moremi-use-cases-select__input-wrapper">
        <select id={selectInputId} className="moremi-use-cases-select__input" onChange={handleSelect}>
          {useCases.map(useCase => (
            <option key={useCase} value={useCase}>{useCase}</option>
          ))}
        </select>
        <FaChevronDown className="moremi-use-cases-select__icon" />
      </div>
    </div>
  )
}

/**
  * @param {Object} props
  * @param {string} props.useCase
  * @param {React.ReactNode} props.children
  */
function UseCasePanel({ useCase, children }) {
  const { selectedUseCase } = useContext(useCaseContext)

  if(selectedUseCase !== useCase) return null
  return children
}

const useCaseContext = createContext({
  selectedUseCase: "",
  /** @param {string} useCase */
  selectUseCase: (useCase) => {},
})
