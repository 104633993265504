import { useState, useCallback } from "react";

export default function useToggledState(initialState){
  const [state, setState] = useState(initialState ?? false)

  const toggleState = useCallback((force) => {
    setState(prev => force ?? !prev)
  }, [])

  return [state, toggleState]
}