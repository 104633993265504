// @ts-check
"use strict"

import React from "react"
import { SyntaxKind, parse, walk } from "html5parser"

/**
  * @param {Object} props
  * @param {string} [props.children]
  * @param {Partial<HtmlRendererComponentMap>} [props.components]
  */
export default function HtmlRenderer({ children = "", components = {} }) {
  if(typeof children != "string") throw new TypeError("Children must be a string")

  return (
    React.createElement(
      React.Fragment, null, htmlToReact(children, { components })
    )
  )
}

/**
  * @param {string} html
  * @param {Object} options
  * @param {Partial<HtmlRendererComponentMap>} [options.components]
  */
function htmlToReact(html, { components } = {}) {
  if(typeof html != "string") throw new TypeError("HTML must be a string")
  const ast = parse(html, { setAttributeMap: true })
  /** @type {import("react").ReactNode[]} */
  const result = []

  walk(ast, {
    enter(node, parent) {
      if(parent != null) return;
      result.push(processNode(node, components, 0, result.length))
    }
  })

  return result
}

/**
  * @param {import("html5parser").INode} node
  * @param {Partial<HtmlRendererComponentMap>} [components]
  * @param {number} [depth]
  * @param {number} [index]
  * @returns {import("react").ReactNode}
  */
function processNode(node, components, depth = 0, index = 0) {
  if(node.type === SyntaxKind.Text) return node.value

  const key = getKey(node.name, depth, index)

  if(node.type === SyntaxKind.Tag) {
    const children = node.body?.map((child, index) => (
      processNode(child, components, depth + 1, index)
    ))

    const props = (() => {
      if(node.attributeMap == null) return { key: "" }
      return (
        Object.fromEntries(
          Object.entries(node.attributeMap).map(([key, attr]) => [key, attr.value?.value])
        )
      )
    })()
    Object.assign(props, { key, children })

    const component = getComponent(node, components)
    if(component != null) return component(node, key, { props, children })

    return React.createElement(node.name, props, children )
  }

  // @ts-ignore
  throw new Error(`Invalid node type: ${node.type}`)
}

/**
  * @param {import("html5parser").INode} node
  * @param {Partial<HtmlRendererComponentMap>} [components]
  */
function getComponent(node, components) {
  if(components == null) return
  if(node.type !== SyntaxKind.Tag) return
  return components[/** @type {keyof HtmlRendererComponentMap} */(node.name)]
}

/**
  * @param {string} name
  * @param {number} depth
  * @param {number} index
  */
function getKey(name, depth, index) {
  return `${name}|${depth}>${index}`
}

/**
  * @template [T = string]
  * @param {import("html5parser").ITag} node
  * @param {string} name
  * @param {(value: string) => T} [parse]
  */
export function getAttribute(node, name, parse) {
  const value = node.attributeMap?.[name]?.value?.value
  if(value == null) return undefined
  if(parse == null) return /** @type {T}*/(value)
  return parse(value)
}

/**
  * @typedef {{[key in keyof HTMLElementTagNameMap]: HtmlRendererComponent<key>} & {[key: string]: HtmlRendererComponent<string>}} HtmlRendererComponentMap
  */

/**
  * @template {keyof HTMLElementTagNameMap | (string & {})} K
  *
  * @callback HtmlRendererComponent
  * @param {import("html5parser").ITag} node
  * @param {string} key
  * @param {Object} options
  * @param {Record<string, string | undefined>} options.props
  * @param {import("react").ReactNode[]} [options.children]
  * @returns {import("react").ReactNode}
  */
