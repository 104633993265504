import { useEffect } from "react";

/**
 * Add event listeners to DOM elements when component mounts
 * 
 * Cleans up events automatically when component unmounts
 * 
 * @template {HTMLElement | Window | Document} T
 * @template {keyof (T extends HTMLElement ? HTMLElementEventMap: T extends Window ? WindowEventMap: T extends Document ? DocumentEventMap: never)} U
 * 
 * @param {T} element
 * @param {U} event
 * @param {(ev: T extends HTMLElement ? HTMLElementEventMap[U]: T extends Window ? WindowEventMap[U]: T extends Document ? DocumentEventMap[U]: never) => void} listener 
 * @param {(boolean | AddEventListenerOptions)=} options
 */
export default function useEventListener(element, event, listener, options) {
    useEffect(() => {
        element.addEventListener(event, listener, options)

        return () => {
            element.removeEventListener(event, listener)
        }
    }, [])
}